import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';

import config from '../../config';

const IndexPage = () => (
    <Layout>
        <section id="banner">
            <div className="inner">
                <div className="logo">
                    <span className="icon fa-code"></span>
                </div>
                <h2>{ config.heading }</h2>
                <p>{ config.subHeading }</p>
            </div>
        </section>

        <section id="wrapper">
            <section id="one" className="wrapper spotlight style1">
                <div className="inner">
                    <img src={ pic1 } alt="" className="image"/>
                    <div className="content">

                        <h2 className="major">UI Driven</h2>
                        <p>
                            With Flutter you are in control of every pixel on the screen.
                            Stop sacrificing your UI because of technical bottleneck.
                            Let imagination to take your applications to the next level.
                        </p>
                    </div>
                </div>
            </section>

            <section id="two" className="wrapper alt spotlight style2">
                <div className="inner">
                    <img src={ pic2 } alt="" className="image"/>
                    <div className="content">
                        <h2 className="major">Native Feel</h2>
                        <p>
                            Both Android and iOS users expect platforms to have certain features.
                            Flutter’s widgets incorporate all critical platform differences such as scrolling,
                            navigation, icons and fonts.
                        </p>
                    </div>
                </div>
            </section>

            <section id="three" className="wrapper spotlight style3">
                <div className="inner">
                    <img src={ pic3 } alt="" className="image"/>
                    <div className="content">
                        <h2 className="major">Native performance</h2>
                        <p>
                            Flutter code is compiled to native ARM machine code using Dart's compilers. Thus
                            Flutter gives you full native performance on both iOS and Android.
                            Smooth animations in 60fps.
                        </p>
                    </div>
                </div>
            </section>

            <section id="four" className="wrapper alt style1">
                <div className="inner">
                    <h2 className="major">Our Offer</h2>
                    <p>
                        In our offer you can find everything to cover whole application making process. From basic
                        designs to production build.
                    </p>
                    <section className="features">
                        <article>
                            <img src={ pic4 } alt="" className="image"/>
                            <h3 className="major">Design</h3>
                            <p>
                                We do full application UI and UX design to fit all your needs.
                            </p>
                        </article>
                        <article>
                            <img src={ pic5 } alt="" className="image"/>
                            <h3 className="major">Development</h3>
                            <p>
                                Take advantage of cross platform approach.
                                Develop applications for both iOS and Android with a breeze.
                            </p>
                        </article>
                        <article>
                            <img src={ pic6 } alt="" className="image"/>
                            <h3 className="major">Maintenance</h3>
                            <p>
                                Have a existing application which you want to develop further?
                                We are happy to help.
                            </p>
                        </article>
                        <article>
                            <img src={ pic7 } alt="" className="image"/>
                            <h3 className="major">Consultations</h3>
                            <p>
                                We provide full support in all steps of mobile applications development and deployment
                                process.
                            </p>
                        </article>
                    </section>

                </div>
            </section>
        </section>
    </Layout>
);

export default IndexPage;
